@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body{
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: #f3f2ef;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* .drop{
  backdrop-filter: blur(24px);
} */

footer{
  padding: 2rem 0 1rem;
  background-image: url('./Assets/footer2.jpg');
  color: white !important;
  background-position: center;
}

input{
  outline: none;
  font-weight: normal;
}

.shop{
  background-image: url('./Assets/footer2.jpg');
  background-position: center;
}